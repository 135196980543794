html,
body {
  background: url("https://res.cloudinary.com/robin-random-files/image/upload/q_auto:eco/v1585231325/Park-Eindhoven-Henri-Dunant_y6fesp.jpg")
    no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  text-align: center;
}
.App {
  height: 90vh;
}
.content {
  height: 90vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
}
main {
  flex: 1 0 auto;
}
footer {
  padding-bottom: 1rem;
}
.bufferTop {
  height: 7vh;
}
.pageContent {
  width: 500px;
}
.background {
  background-color: rgba(233, 233, 233, 0.5);
  padding: 2rem;
  border-radius: 1rem;
}
.introScreen {
  width: 600px;
}
@media only screen and (max-width: 500px) {
  .pageContent,
  .introScreen {
    width: 90%;
  }
}
.questionAnswers {
  margin-bottom: 2rem;
  text-align: left;
  transform: translateX(25%);
  max-width: 80%;
}
.radioButton {
  margin-right: 5px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
.answerLabel {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  color: white;
}
h1 {
  margin-bottom: 3rem;
}
h3 {
  margin-bottom: 1rem;
}
.answerLabel {
  margin: 0 1rem;
  font-size: 1.3rem;
}
.languageButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.langFlag {
  height: 50px;
  width: 50px;
  margin: 5px 0.5rem;
}
.langFlag:active {
  opacity: 0.5;
  border-radius: 2rem;
  height: 50px;
}
nav {
  width: 100%;
  text-align: right;
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  .toggleButton {
    margin: 10px;
    height: 40px;
    padding: 5px;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
  }
  .toggleButton:hover {
    background-color: lightgray;
  }
  .toggleButton:active {
    background-color: gray;
  }
  .languageButtons {
    display: none;
  }
  .flagContainer {
    position: absolute;
    width: 100%;
  }
  .languageButtons.show {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    background-color: rgb(211, 211, 211, 0.5);
  }
  nav.show {
    background-color: rgb(211, 211, 211, 0.5);
  }
}
.shareIcon {
  margin: 0 10px;
}
